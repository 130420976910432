export enum baseUrlTypes {
  app = 'app',
  reactApp = 'reactApp',
  api = 'api'
}

const baseUrls = {
  local: {
    [baseUrlTypes.app]: 'http://localhost:3001',
    [baseUrlTypes.reactApp]: 'http://localhost:3000',
    [baseUrlTypes.api]: 'http://127.0.0.1:14281/',
  },
  stage: {
    [baseUrlTypes.app]: 'https://test-app.carbook.pro',
    [baseUrlTypes.reactApp]: 'https://test-my.carbook.pro',
    [baseUrlTypes.api]: 'https://test-api.carbook.pro/',
  },
  dev: {
    [baseUrlTypes.app]: 'https://dev-app.carbook.pro',
    [baseUrlTypes.reactApp]: 'https://dev-my.carbook.pro',
    [baseUrlTypes.api]: 'https://dev-api.carbook.pro/',
  },
  production: {
    [baseUrlTypes.app]: 'https://app.carbook.pro',
    [baseUrlTypes.reactApp]: 'https://my.carbook.pro',
    [baseUrlTypes.api]: 'https://api.carbook.pro/',
  },
};

export const getApiUrl = () => getBaseUrl(baseUrlTypes.api)

export const getBaseUrl = (type: baseUrlTypes = baseUrlTypes.reactApp ) => {
  let baseUrl;
  console.log(window.location.origin)
  switch (window.location.origin) {
    case baseUrls.stage.app:
      baseUrl = baseUrls.stage[type];
      break;
    case baseUrls.dev.app:
      baseUrl = baseUrls.dev[type];
      break;
    case baseUrls.production.app:
      baseUrl = baseUrls.production[type];
      break;
    case baseUrls.local.app:
      baseUrl = baseUrls.local[type];
      break;
    default:
      baseUrl = baseUrls.local[type];
  }
  
  return baseUrl;
};
