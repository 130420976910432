import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ObjectLike } from 'src/shared/types/object';
import axiosAPI from 'src/shared/utils/api';
import { getToken } from 'src/shared/utils/helpers';

export interface ConfigState {
  user: ObjectLike;
  status?: string;
}

const initialState: ConfigState = {
  user: {},
};

export const getConfig = createAsyncThunk('config/getConfig', async () => {
  const token = getToken()
  if(!token) return;
  
  const response = await axiosAPI('POST', '/login_by_token', null, {token });
  return response;
});

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<ObjectLike>) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConfig.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getConfig.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(getConfig.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setUser } = configSlice.actions;

export const selectUser = (state: RootState) => state.config.user;

export default configSlice.reducer;
