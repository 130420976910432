import { LayoutProps, Layout as AntdLayout } from 'antd';
import { FC, useRef, useState } from 'react';
import { Header } from './header';
import { Navigation } from './navigation';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'src/app/hooks';
import {  selectUser } from '../../app/config/configSlice';
import { Footer } from './footer';
const { Content, Footer: AntdFooter } = AntdLayout;

interface ExtendedLayoutProps extends LayoutProps {
  isMobile?: boolean;
  collapsed?: boolean;
}

export const Layout: FC<ExtendedLayoutProps> = (props) => {
  const user = useAppSelector(selectUser);

  console.log(user);

  const [collapsed, setCollapsed] = useState(false);
  const handleCollapse = (newValue: boolean) => setCollapsed(newValue);
  const drawerContainerRef = useRef(null);

  return (
    <AntdLayout
      {...props}
      // style={{ height: 'auto !important', minHeight: '100%' }}
    >
      <Header handleCollapse={handleCollapse} />
      <div
        ref={drawerContainerRef}
        style={{ display: 'flex', alignSelf: 'stretch', flexDirection: 'column', height: '100%' }}
      >
        <AntdLayout>
          <Navigation collapsed={collapsed} handleCollapse={handleCollapse} drawerContainerRef={drawerContainerRef} />
          <Content>
            <Outlet />
          </Content>
        </AntdLayout>
        <AntdFooter><Footer /></AntdFooter>
      </div>
    </AntdLayout>
  );
};
