import { FC } from 'react';
import { PhoneFilled, MailFilled } from '@ant-design/icons';
import { Space } from '../../shared/components/space';
import './login.scss';

export const Login: FC = () => {
  return (
    <Space align="center" className={'loginPage'}>
      <div className={'loginWrap'}>
        {/* <LoginForm /> */}
        <div className={'info'}>
          <div>
            <span className={'title'}>Контакти</span>
            <div className={'contactsWrap'}>
              <div className={'contacts'}>
                <a className={'link'} href="tel:380504216648">
                  <PhoneFilled className={'icon'} />
                  +380 (50) 421 6648
                </a>
                <a className={'link'} href="tel:380503567189">
                  <PhoneFilled className={'icon'} />
                  +380 (50) 356 7189
                </a>
                <a className={'link'} href="tel:380953508289">
                  <PhoneFilled className={'icon'} />
                  +380 (95) 350 8289
                </a>
                <a className={'link'} href="tel:380503786817">
                  <PhoneFilled className={'icon'} />
                  +380 (50) 378 6817
                </a>
              </div>

              <a className={'link'} href="mailto:sales@carbook.ua">
                <MailFilled className={'icon'} />
                sales@carbook.ua
              </a>
            </div>
          </div>
          <div>
            <span className={'title'}>Соцмережі</span>
            <div className={'socials'}>
              <a href="https://t.me/carbook_pro" rel="noreferrer" target="_blank">
                {/* <img alt="logo" className={'social'} src={images.telegram} /> */}
              </a>
              <a href="https://www.instagram.com/carbook.pro" rel="noreferrer" target="_blank">
                {/* <img alt="logo" className={'social'} src={images.instagram} /> */}
              </a>
              <a href="https://www.facebook.com/carbook.soft" rel="noreferrer" target="_blank">
                {/* <img alt="logo" className={'social'} src={images.facebook} /> */}
              </a>
              <a href="https://www.youtube.com/@carbook-pro/featured" rel="noreferrer" target="_blank">
                {/* <img alt="logo" className={'social'} src={images.youtube} /> */}
              </a>
            </div>
          </div>
          <div className={'helpWrap'}>
            <span className={'title'} style={{ padding: 0, marginRight: 6 }}>
              База знань Карбук -{' '}
            </span>
            <a className={'help'} href="https://help.carbook.pro/" rel="noreferrer" target="_blank">
              HELP.CARBOOK.PRO
            </a>
          </div>
        </div>
      </div>
    </Space>
  );
};
