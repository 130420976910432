import { FC } from 'react';
import './styles.scss';
import { Link } from 'src/shared/components/link';
import images from 'src/shared/utils/images';
import { TrademarkOutlined } from '@ant-design/icons';

export const Footer: FC = () => {
  return (
    <div className="footerContainer">
      <div>
        Support:{' '}
        <span
          className="highlight"
          onClick={() => {
            navigator.clipboard.writeText('+380443893355');
          }}
        >
          +38(044) 389-3355
        </span>{' '}
        or{' '}
        <a className="highlight" href="mailto:support@carbook.pro">
          support@carbook.pro
        </a>
      </div>
      <div className='userAgreement'>
        <Link to="#" className='highlight'>User agreement</Link>
        <img
          alt="logo"
          className='userAgreementLogo'
          src={images.carbookLogo2}
          // style={{ margin: 8 }}
        />
        <TrademarkOutlined />
      </div>
    </div>
  );
};
