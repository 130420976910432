/// https://redux.js.org/tutorials/essentials/part-2-app-structure
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import configReducer from './config/configSlice';

export const store = configureStore({
  reducer: {
    config: configReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
