import { message } from 'antd';
import { redirect } from "react-router-dom";
import axios from 'axios';
import _ from 'lodash';
import qs from 'qs';
// import { emitError } from 'core/ui/duck';
import ROUTES from '../constants/routes';
import { getToken } from './helpers';
import { useAppDispatch } from 'src/app/hooks';
import { ObjectLike } from '../types/object';
import { getApiUrl } from 'src/core/base-url';

export const API = getApiUrl();
/*
    ? 'https://dev-api.carbook.pro'
    : 'https://dev-api.carbook.pro';
// export const API = __DEV__ ? 'http://127.0.0.1:14281' : 'dev-api.carbook.pro';
*/

const apiC = _.trim(API, '/');
// const apiC = trim(__API_URL__, '/');

// class ResponseError extends Error {
//     constructor(response, status) {
//         super();

//         Error.captureStackTrace(this, this.constructor);

//         this.name = this.constructor.name;
//         this.message = 'Response Error';
//         this.response = response;
//         this.status = status || 500;
//     }
// }

/* eslint-disable complexity */

interface axiosAdditionalParams {
    rawResponse?: boolean;
    handleErrorInternally?: boolean;
    url?: string;
    noAuth?: boolean,
    headers?: Item[]
}

interface axiosOptions {
    method: string;
    url: string;
    headers: ObjectLike;
    data?: ObjectLike;
}

export default function axiosAPI(method: string, endpoint: string, query: Nullable<ObjectLike>, body: NonNullable<ObjectLike>, params: axiosAdditionalParams = {}) {
    const { rawResponse, handleErrorInternally, url, noAuth, headers } = params;
    const endpointC = _.trim(endpoint, '/'); // trim all spaces and '/'
    const handler = endpointC ? `/${endpointC}` : ''; // be sure that after api will be only one /
    const methodU = _.toUpper(method);
    const omittedQuery = _.omitBy(query, (value: string) => _.isString(value) && _.isEmpty(value));
    const queryString = qs.stringify(omittedQuery, {
        skipNulls: true,
        arrayFormat: 'brackets'
    });

    const req_url = `${url || apiC}${handler}${queryString ? `?${queryString}` : ''}`

    const options: axiosOptions = {
        method: methodU,
        url: req_url,
        headers: headers || {
            'content-type': 'application/json',
            'Cache-Control': 'no-cache',
            'Access-Control-Request-Headers': '*'
            // 'Access-Control-Request-Method':  '*',
        },
    };

    const token = getToken();

    if (!noAuth && token) {
        Object.assign(options.headers, {
            Authorization: `${token}`
        });
    }

    if (methodU === 'POST' || methodU === 'PUT' || methodU === 'DELETE') {
        options.data = body;
    }

    axios(options).then(response => {
        const { status } = response;
        const dispatch = useAppDispatch();

        switch (true) {
            case status >= 200 && status < 300:
                return rawResponse ? response : response;
            case status === 400:
                if (!handleErrorInternally) {
                    dispatch(redirect(`${ROUTES.exception}/400`));

                    return;
                }
            // dispatch(emitError({ message, status }));
            // throw new ResponseError(await response.json(), status);
                break;
            case status === 401:
                // dispatch(logout());
                window.location.reload();
            // throw new ResponseError(await response.json(), status);
                break;
            case status === 403:
                if (!handleErrorInternally) {
                    dispatch(redirect(`${ROUTES.exception}/403`));

                    return;
                }
                // dispatch(emitError({ message, status }));
                message.error('Error! 403');
            // throw new ResponseError(await response.json(), status);
                break;
            case status >= 404 && status < 422:
                if (!handleErrorInternally) {
                    dispatch(redirect(`${ROUTES.exception}/404`));

                    return;
                }
                // dispatch(emitError({ message, status }));
                message.error('Error!');
            // throw new ResponseError(await response.json(), status);
                break;
            case status >= 500 && status <= 504:
                if (!handleErrorInternally) {
                    dispatch(redirect(`${ROUTES.exception}/500`));

                    return;
                }
                // dispatch(emitError({ message, status }));
                message.error('Error! 500');
            // throw new ResponseError(await response.json(), status);
                break;
            default:
            // throw new ResponseError(await response.json(), status);
                break;
        }
    })
}
