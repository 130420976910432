import { FC } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { Login } from '../modules/login';

import ROUTES from '../shared/constants/routes';
import { Layout } from 'src/core/layout';

const Home: FC = () => {
  return (
    <div>
      <p>Home page</p>
    </div>
  );
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: ROUTES.initial,
        element: <Home />,
      },
      {
        path: 'test',
        element: <div>bibaboba</div>,
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ],
  },
  {
    children: [
      {
        path: ROUTES.login,
        element: <Login />,
      },
    ],
  },
]);

export default router;
