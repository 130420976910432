import { BarcodeOutlined, BellOutlined, FileAddOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { Link } from 'src/shared/components/link';
import { Tooltip } from 'src/shared/components/tooltip';
import './styles.scss';

const testItems = [
  {
    icon: <BellOutlined />,
    tooltip: 'Tasks',
  },
  {
    icon: <BarcodeOutlined />,
    tooltip: 'Barcode',
  },
  {
    icon: <FileAddOutlined />,
    tooltip: 'Quick navigation',
  },
  {
    icon: <FileAddOutlined />,
    tooltip: 'Quick navigation',
  },
  {
    icon: <FileAddOutlined />,
    tooltip: 'Quick navigation',
  },
  {
    icon: <FileAddOutlined />,
    tooltip: 'Quick navigation',
  },
  {
    icon: <FileAddOutlined />,
    tooltip: 'Quick navigation',
  },
  {
    icon: <FileAddOutlined />,
    tooltip: 'Quick navigation',
  },
];

export const ShortcutsBlock: FC = () => {
  return (
    <div className='shortcutsContainer'>
      {testItems.map(({ icon, tooltip }) => (
        <Tooltip title={tooltip} key={tooltip}>
          <Link to="#" className="appstore">
            {icon}
          </Link>
        </Tooltip>
      ))}
    </div>
  );
};
