import router from './routing/routes';
import { RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import './app.scss';
import { FC, useEffect } from 'react';
import { setToken } from './shared/utils/helpers';
import { connect } from 'react-redux';
import { RootState } from './app/store';
import { getConfig } from './app/config/configSlice';

export interface AppProps {
  getConfig: () => void;
}

const App: FC<AppProps> = ({getConfig}) => {
  useEffect(() => {
    // Parse the JWT token from the URL query parameter
    const queryParams = new URLSearchParams(window.location.search);
    const jwtToken = queryParams.get('token');

    if (jwtToken) {
      setToken(jwtToken);

      const url = new URL(location.href);
      url.searchParams.delete('token');
      window.history.replaceState({}, "", url);
    }
    
    getConfig();
  }, [getConfig]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00b96b',
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
};

export default connect((state: RootState) => state.config, {getConfig})(App);
