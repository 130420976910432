import { FC } from 'react';
import { HeaderMenu } from './headerMenu';
import { Layout } from 'antd';
import './styles.scss'

const {Header: AntdHeader} = Layout
interface HeaderProps {
  collapsed?: boolean;
  handleCollapse: CallableFunction
}

export const Header: FC<HeaderProps> = ({handleCollapse}) => {
  return <AntdHeader className='header'>
    <HeaderMenu handleCollapse={handleCollapse} />

  </AntdHeader>
};
