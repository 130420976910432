import { FC, RefObject } from 'react';
import { Drawer } from 'src/shared/components/drawer';
// import { Layout as AntdLayout } from 'antd';
// const { Sider } = AntdLayout;

interface NavigationProps {
  collapsed: boolean;
  handleCollapse: CallableFunction;
  drawerContainerRef: RefObject<HTMLDivElement>;
}

export const Navigation: FC<NavigationProps> = ({ collapsed, handleCollapse, drawerContainerRef }) => {
  return (
    // <Sider>
      <Drawer
        headerStyle={{ display: 'none' }}
        open={collapsed}
        onClose={() => handleCollapse(false)}
        placement="left"
        style={{ position: 'relative' }}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        getContainer={() => drawerContainerRef.current!}
      >
        
      </Drawer>
    // </Sider>
  );
};
