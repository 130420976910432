import { FC } from 'react';
import './styles.scss';
import { GlobalSearchBlock } from './globalSearchBlock';
import { ShortcutsBlock } from './shortcutsBlock';
import { ProfileBlock } from './profileBlock';

interface HeaderMenuProps {
  collapsed?: boolean;
  handleCollapse: CallableFunction;
}

export const HeaderMenu: FC<HeaderMenuProps> = ({ handleCollapse }) => {
  return (
    <div className="headerContainer">
      <GlobalSearchBlock handleCollapse={handleCollapse} />
      <ShortcutsBlock />
      <ProfileBlock />
    </div>
  );
};
