import { BankOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { Button } from 'src/shared/components/button/button.component';
import { Popover } from 'src/shared/components/popover/popover.component';
import { Select } from 'src/shared/components/select';

export const GlobalRequisitesSelector: FC = () => {
  return (
    <Popover content={<Select allowClear />} placement="right" trigger={['click']}>
      <Button icon={<BankOutlined />} title='requisites' />
    </Popover>
  );
};
