import { AppstoreFilled, GlobalOutlined, HomeOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { FC } from 'react';
import { Dropdown } from 'src/shared/components/dropdown';
import { Tooltip } from 'src/shared/components/tooltip';
import { Link } from 'src/shared/components/link';
import { Avatar } from 'src/shared/components/avatar';
import './styles.scss';

const menu: MenuProps = {
  items: [
    {
      label: 'Business',
      icon: <HomeOutlined />,
      key: 'business',
    },
    {
      label: 'Website',
      icon: <GlobalOutlined />,
      key: 'website',
    },
    {
      label: 'Log out',
      icon: <PoweroffOutlined />,
      key: 'logout',
    },
  ],
};

export const ProfileBlock: FC = () => {
  return (
    <div className="profileContainer">
      <div className='profileActions'>
        <Tooltip placement="topLeft" title="header.profile">
          <Link className='profileLink' to="#">
            <Avatar icon={<UserOutlined />} className='avatar'/>
            <span>Name Surname</span>
          </Link>
        </Tooltip>
        <Dropdown trigger={['click']} menu={menu} overlayStyle={{ zIndex: 11 }}>
          <Tooltip title="navigation.operations" zIndex={10}>
            <AppstoreFilled className="profileIcon" />
          </Tooltip>
        </Dropdown>
      </div>
      <div>
        Switch business
      </div>
    </div>
  );
};
