import { SearchOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { Autocomplete } from 'src/shared/components/autocomplete';
import { Input } from 'src/shared/components/input';
import { Space } from 'src/shared/components/space';
import { Tooltip } from 'src/shared/components/tooltip';
import images from 'src/shared/utils/images';
import { GlobalRequisitesSelector } from './globalRequisitesSelector';
// import './styles.scss'

interface GlobalSearchBlockProps {
  handleCollapse: CallableFunction;
}

export const GlobalSearchBlock: FC<GlobalSearchBlockProps> = ({ handleCollapse }) => {
  return (
    <Space>
      <Tooltip
        title="menu"
      >
        <img
          alt="logo"
          className="headerLogo"
          onClick={() => {
            handleCollapse(true);
          }}
          // src={user.secondaryLogoUrl || images.cb}
          src={images.cb}
        />
      </Tooltip>
      <Autocomplete><Input suffix={<SearchOutlined />}/></Autocomplete>
      <GlobalRequisitesSelector />
    </Space>
  );
};
